<template>
  <div class="card-toolbar">
    <router-link
      v-if="backText"
      :to="backUrl"
      class="btn btn-light-primary font-weight-bolder mr-2"
    >
      <i class="ki ki-long-arrow-back icon-sm"></i>
      {{ backText }}
    </router-link>
    <router-link
      v-else
      :to="backUrl"
      class="btn btn-light-primary font-weight-bolder mr-2"
    >
      <i class="ki ki-long-arrow-back icon-sm"></i>
      {{ $t('MAIN_MENU.BACK_TO_LIST') }}
    </router-link>
    <div class="js-btn-group btn-group">
      <button
        @click="submit('1')"
        type="submit"
        class="btn btn-primary font-weight-bolder"
      >
        <i class="ki ki-check icon-sm"></i>
        <span v-if="buttonText">{{ type }} {{ buttonText }}</span>
        <span v-else>{{ $t('MAIN_MENU.ADD') }}</span>
      </button>
      <button
        @click="toggleDropDown"
        type="button"
        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></button>
      <div
        class="js-dropdown-menup dropdown-menu dropdown-menu-sm dropdown-menu-right"
        style="
          position: absolute;
          transform: translate3d(-35px, 38px, 0px);
          top: 0px;
          left: 0px;
          will-change: transform;
        "
      >
        <ul class="nav nav-hover flex-column">
          <li class="nav-item">
            <button @click="submit('2')" type="submit" class="nav-link">
              <i class="nav-icon flaticon2-add-1"></i>
              <span class="nav-text">{{ $t('GENERAL.SAVE_AND_ADD_NEW') }}</span>
            </button>
          </li>
          <li class="nav-item">
            <button @click="submit('3')" type="submit" class="nav-link">
              <i class="nav-icon flaticon2-power"></i>
              <span class="nav-text">{{ $t('GENERAL.SAVE_AND_EXIT') }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardToolbar',
  props: {
    backText: {
      type: String,
      required: false,
    },
    backUrl: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    submit(type) {
      this.$emit('submitEvent', type)
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen
      document.querySelector('.js-btn-group').classList.toggle('show')
      document.querySelector('.js-dropdown-menup').classList.toggle('show')
    },
  },
}
</script>
